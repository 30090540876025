<script lang="ts">
  import MaterialIcon from '$lib/MaterialIcon.svelte';
  import { mdiDotsCircle } from '@mdi/js';
</script>

<MaterialIcon
  label="Loading"
  path={mdiDotsCircle}
  spin
  spinRate="2s"
  spinTimingFunction="ease-in-out"
  {...$$props}
/>
